<template>
  <span class="tooltip" ref="tooltip">
    <slot />
  </span>
</template>

<script>
  import tippy from 'tippy.js';

  export default {
    name: 'BasicTooltip',
    props: {
      content: {
        type: String,
        required: true,
      },
      placement: {
        type: String,
        default: 'bottom',
      },
      theme: {
        type: String,
        default: 'grey'
      },
      arrow: {
        type: Boolean,
        default: false,
      },
      duration: {
        type: Array,
        default: () => [100, 150]
      },
      maxWidth: {
        type: Number,
        default: 350,
      }
    },
    data() {
      return {
        tippy: null
      }
    },
    watch: {
      content(newValue) {
        this.tippy.setContent(newValue);
      }
    },
    mounted() {
      const {
        content,
        placement,
        theme,
        arrow,
        duration,
        maxWidth,
      } = this;

      this.tippy = tippy(this.$refs.tooltip, {
        content,
        placement,
        theme,
        arrow,
        duration,
        maxWidth,
      });
    },
    beforeDestroy() {
      if (this.tippy) {
        this.tippy.destroy();
      }
    },
    methods: {
      hide() {
        if (this.tippy) {
          this.tippy.hide();
        }
      }
    }
  }
</script>