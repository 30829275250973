$(function(){

  // если блока нет на странице, то код дальше можно не выполнять.
  if( $('.companies_show_page').length === 0 ) return;

  //$('.about_company a').attr('target', '_blank');

  $('.tab.rating_slice').on('click', function(event) {
    var year = $(this).data('year');
    $('.tab.rating_slice').removeClass('open');
    $(this).addClass('open');
    $('.rating_slice_body').addClass('hidden');
    $('.rating_slice_body[data-year="' + year + '"]').removeClass('hidden');
  });
});