$(function() {
  if ($('.company_scores').length === 0) return;

  $('body').on('click', '.company_score .average', function(){
    var el = $(this).find('.toggle_details');
    if (el.hasClass('collapse')) {
      el.removeClass('collapse');
    } else {
      el.addClass('collapse');
    }
    $(this).closest('.company_score').find('.score_details').toggleClass('hidden');
  });

  $('body').on('click', '#new_comment_form .cancel_comment_form', function(){
    $(this).closest('#new_comment_form').remove();
    $('.score_comment').removeClass('hidden');
  });

  $('body').on('click', '.js-toggle-score-visibility', function(){
    $(this).closest('.company_score').find('.toggle_hidden').toggleClass('hidden');
    $(this).closest('.controls').remove();
  });
});

$(function(){
  if( $('.js-scores-sort-dropdown').length === 0 ) return;

  $(document).on('change', '.js-scores-sort-dropdown input', function(){
    $('body').click();
    var sort = $(this).val();
    var url = $('.js-scores-sort-dropdown').data('url');
    $.ajax({
      url: url,
      data: {sort: sort},
      method: 'GET',
      dataType: 'script'
    });
  });

  function showScoresDropdownLabel () {
    var checked = $('.js-scores-sort-dropdown input:checked').map(function (i, val) {
      var wrapper = $(val).closest('.radio_single')
      var text = wrapper.find('label').text()
      return text
    }).toArray()
    var togglerText = checked.length > 0
      ? ('Сортировать ' + checked.join(', '))
      : ''
    var toggler = $('.js-scores-sort-dropdown .toggler')
    var icon = $('<span class="icon-Arrow"></span>')
    toggler.text(togglerText)
    toggler.append(icon)
  }

  showScoresDropdownLabel();

  $(document).on('change', '.js-scores-sort-dropdown input', showScoresDropdownLabel)
});